.ProseMirror {
    padding: 1rem !important;
    border: 1px solid #ddd !important;
    border-radius: 6px !important;
    min-height: 200px !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
}

.ProseMirror strong {
    font-weight: bold !important;
}


/* button {
    margin-right: 0.5rem;
    padding: 4px 8px;
    border: 1px solid #ccc;
    background: #fff;
    cursor: pointer;
}

button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
} */